<template lang="pug">
.auth.app.flex-row.align-items-center
  .container
    .row.justify-content-center
      .card.p-4
        .card-body
          h1 {{ $t('title') }}
          p.text-muted {{ $t('prompt') }}
          .btn-toolbar.mb-4
            .btn-group.nav
              router-link.nav-item.btn.btn-outline-secondary(:to = '{ name: "Login" }') {{ $t('password.title') }}
              router-link.nav-item.btn.btn-outline-secondary(:to = '{ name: "Card" }') {{ $t('card.title') }}
              router-link.nav-item.btn.btn-outline-secondary(:to = '{ name: "Mobile" }') {{ $t('mobile.title') }}
              router-link.nav-item.btn.btn-outline-secondary(:to = '{ name: "Smart" }') {{ $t('smart.title') }}
          router-view
</template>


<script>
export default {
  name: 'auth',

  i18nOptions: { namespaces: 'auth' }
}
</script>


<style scoped lang="scss">
.btn-group, .btn-group a {
  flex-wrap: nowrap;
}
</style>
