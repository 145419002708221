<template lang="pug">
.card-auth
  p.mb-4 {{ $t('card.p') }}
  p.text-danger.mb-4(v-if='error') {{ error }}
  button.btn.btn-primary.px-4(type           = 'button'
                              @click.prevent = 'onLogin'
                              :disabled      = 'isIdCardLogin') {{ $t('login') }}
    i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("auth:login")')
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'card-auth',

  props: {
    redirect: {
      type: String,
      default: null
    }
  },

  i18nOptions: { namespaces: 'auth' },

  computed: {
    ...mapState('auth', ['isIdCardLogin', 'isLoggedIn', 'error'])
  },

  created () {
    this.clearError()
  },

  methods: {
    ...mapActions('auth', ['idCardLogin', 'clearError']),
    async onLogin () {
      await this.idCardLogin()
      if (this.isLoggedIn) this.$router.push(this.redirect || '/')
    }
  }
}
</script>
