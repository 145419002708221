<template lang="pug">
.mobile
  template(v-if = 'mobidData')
    p.mb-0 {{ $t('mobile.p1', { code: mobidData.challenge }) }}
    p {{ $t('mobile.p2') }}
    .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
  form(v-else
       @submit.prevent='onSubmit')
    fi-form-field
      .input-group
        .input-group-prepend: span.input-group-text: i.fas.fa-phone
        fi-phone-input.form-control(v-model.trim = 'authData.phone'
                                    :placeholder = '$t("mobile.phone")'
                                    @input       = 'clearError')
    fi-form-field.mb-4
      .input-group
        .input-group-prepend: span.input-group-text: i.fa.fa-user
        input.form-control(v-model.trim = 'authData.idCode'
                           type         = 'text'
                           :placeholder = '$t("smart.idCode")'
                           @input       = 'clearError')
    p.text-danger.mb-4(v-if = 'error') {{ error }}
    button.btn.btn-primary.px-4(type      = 'submit'
                                :disabled = '!(authData.phone && authData.idCode)') {{ $t('login') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("auth:login")')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiPhoneInput from '@/components/FiPhoneInput'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'mobile',
  components: { FiFormField, FiPhoneInput },
  props: {
    redirect: {
      type: String,
      default: null
    }
  },

  i18nOptions: { namespaces: 'auth' },

  data: () => ({
    authData: {
      phone: '',
      idCode: ''
    }
  }),

  computed: {
    ...mapState('auth', ['isLoggedIn', 'error', 'mobidData'])
  },

  watch: {
    isLoggedIn (val) {
      if (val) this.$router.replace(this.redirect || '/')
    }
  },

  created () {
    this.clearError()
  },

  methods: {
    ...mapActions('auth', ['mobidLogin', 'clearError']),
    async onSubmit () {
      this.mobidLogin(this.authData)
    }
  }
}
</script>
