<template lang='pug'>
form.reset-request.form(@submit.prevent = 'onSubmit')
  p.text-muted {{ $t('prompt') }}
  .form-group-sm
    input.form-control-sm.form-control(v-model.trim = 'login'
                                       type         = 'text'
                                       :placeholder = '$t("login")')
    .form-text(v-if='result') {{ $t('result') }}

  .row.align-items-center.mt-4
    .col-6
      button.btn.btn-primary.px-4(type      = 'submit'
                                  :disabled = '!(login)') {{ $t('common:send') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("reset:request")')

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'reset-request',

  i18nOptions: {},

  data () {
    return {
      login: null,
      result: false
    }
  },

  methods: {
    ...mapActions('passwordReset', ['sendResetRequest']),
    async onSubmit () {
      this.result = false
      this.result = await this.sendResetRequest(this.login)
    }
  }
}
</script>


<i18n>
en:
  prompt: "Link to password reset form will be send to specified login email address"
  result: "If user with this login exists we will sent you reset token"
  login:  "Login"
et:
  prompt: "Link to password reset form will be send to specified login email address"
  result: "If user with this login exists we will sent you reset token"
  login:  "Login"
ru:
  prompt: "Link to password reset form will be send to specified login email address"
  result: "If user with this login exists we will sent you reset token"
  login:  "Login"
</i18n>
