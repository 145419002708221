<template lang="pug">
.smart
  template(v-if = 'smartData')
    p.mb-0 {{ $t('smart.p1', { code: smartData.code }) }}
    p {{ $t('smart.p2') }}
    .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
  form(v-else
       @submit.prevent='onSubmit')
    .input-group.mb-4
      .input-group-prepend: span.input-group-text: i.fas.fa-globe
      fi-select(
        v-model  = 'countryCode'
        :options = 'optionsFromClassifier("countries", true)'
      )
    .input-group.mb-4
      .input-group-prepend: span.input-group-text: i.fa.fa-user
      input.form-control(v-model.trim = 'idCode'
                         type         = 'text'
                         :placeholder = '$t("smart.idCode")'
                         @input       = 'clearError')
    p.text-danger.mb-4(v-if = 'error') {{ error }}
    button.btn.btn-primary.px-4(type      = 'submit'
                                :disabled = '!idCode') {{ $t('login') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("auth:login")')
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import FiSelect from '@/components/FiSelect'

export default {
  name: 'smart',
  components: { FiSelect },
  props: {
    redirect: {
      type: String,
      default: null
    }
  },

  i18nOptions: { namespaces: 'auth' },

  data () {
    return {
      idCode: null,
      countryCode: 'ee'
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapState('auth', ['isLoggedIn', 'error', 'smartData'])
  },

  watch: {
    isLoggedIn (val) {
      if (val) this.$router.replace(this.redirect || '/')
    }
  },

  async created () {
    this.clearError()
    await this.loadCountryClassifier()
  },

  methods: {
    ...mapActions('auth', ['smartLogin', 'clearError']),
    ...mapActions('classifiers', ['loadCountryClassifier']),
    onSubmit () {
      this.smartLogin(this.$data)
    }
  }
}
</script>
