<template lang='pug'>
form.reset-new-password.form(@submit.prevent = 'onSubmit')
  p.text-muted {{ $t('prompt') }}
  fi-form-field
    input.form-control-sm.form-control(v-model.trim = 'password'
                                       type         = 'password'
                                       :placeholder = '$t("password")')
  fi-form-field
    input.form-control-sm.form-control(v-model.trim = 'passwordRepeat'
                                       type         = 'password'
                                       :placeholder = '$t("passwordConfirm")')
  .alert.alert-danger(v-if = 'error') {{ error }}
  .row.align-items-center
    .col-6
      button.btn.btn-primary.px-4(type      = 'submit'
                                  :disabled = '$v.passwordRepeat.$invalid') {{ $t('setPassword') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("reset:password:save")')
</template>

<script>
import FiFormField from '@/components/FiFormField'
import { validation } from '@/validation'
import { sameAs, required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'reset-new-password',

  components: { FiFormField },

  mixins: [validation],

  props: {
    token: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      password: null,
      passwordRepeat: null,
      error: null
    }
  },

  validations: {
    password: {
      required
    },
    passwordRepeat: {
      required,
      sameAs: sameAs('password')
    }
  },

  methods: {
    ...mapActions('passwordReset', ['saveNewPassword']),
    async onSubmit () {
      this.error = null
      try {
        await this.saveNewPassword({ token: this.token, newPassword: this.password })
        this.$router.replace({ name: 'Auth' })
      } catch (e) {
        this.error = e
      }
    }
  }
}
</script>


<i18n>
en:
  prompt:          "Set new password"
  password:        "New password"
  passwordConfirm: "Confirm new password"
  setPassword:     "Set password"
et:
  prompt:          "Set new password"
  password:        "New password"
  passwordConfirm: "Confirm new password"
  setPassword:     "Set password"
ru:
  prompt:          "Set new password"
  password:        "New password"
  passwordConfirm: "Confirm new password"
  setPassword:     "Set password"
</i18n>
