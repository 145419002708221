<template lang='pug'>
.reset-view.app.flex-row.align-items-center
  .container-fluid
    .row.justify-content-center
      .card.p-4
        .card-body
          h1 {{ $t('title') }}
          router-view
</template>


<script>
export default {
  name: 'reset-view',

  i18nOptions: {}
}
</script>


<i18n>
en:
  title: "Password reset"
et:
  title: "Password reset"
ru:
  title: "Password reset"
</i18n>

<style scoped>

</style>
