<template lang="pug">
.id-logout.app.flex-row.align-items-center
  .container
    .row.justify-content-center
      .col-md-6
        .card.p-4
          .card-body
            h4.card-title {{ $t('idLogout.title') }}
            p {{ $t('idLogout.warning') }}
</template>


<script>
export default {
  name: 'id-logout',

  i18nOptions: { namespaces: 'auth' }
}
</script>
