<template lang="pug">
form.login(@submit.prevent = 'onSubmit')
  .input-group.mb-3
    .input-group-prepend: span.input-group-text: i.fa.fa-user
    input.form-control(v-model.trim = 'username'
                       type         = 'text'
                       :placeholder = '$t("password.username")'
                       @input       = 'clearError')
  .input-group.mb-4
    .input-group-prepend: span.input-group-text: i.fa.fa-lock
    input.form-control(v-model.trim = 'password'
                       type         = 'password'
                       :placeholder = '$t("password.password")'
                       @input       = 'clearError')
  p.text-danger.mb-4(v-if = 'error') {{ error }}
  .row.align-items-center
    .col-6
      button.btn.btn-primary.px-4(type      = 'submit'
                                  :disabled = '!(username && password)') {{ $t('login') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("auth:login")')
    .col-6.text-right
      router-link(:to = '{ name: "ResetRequest" }') {{ $t('password.forgot') }}
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'login',

  props: {
    redirect: {
      type: String,
      default: null
    }
  },

  i18nOptions: { namespaces: 'auth' },

  data () {
    return {
      username: '',
      password: ''
    }
  },

  computed: mapState('auth', ['error', 'isLoggedIn']),

  watch: {
    isLoggedIn (val) {
      if (val) this.$router.replace(this.redirect || '/')
    }
  },

  created () {
    this.clearError()
  },

  methods: {
    ...mapActions('auth', ['login', 'clearError']),
    async onSubmit () {
      await this.login({ ...this.$data })
      this.password = ''
    }
  }
}
</script>


<style scoped lang="scss">
button { white-space: normal; }
.btn-group, .btn-group label { width: 100%; }
</style>
